.results-list {
  position: absolute;
  width: 500px;
  background-color: var(--second-bg) !important;
  color: var(--txt-color);
  box-shadow: 0px 0px 8px #ddd;
  top: 90%;
  max-height: 300px;
  overflow-y: auto;
}

.search-result {
  padding: 10px 20px;
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  color: var(--text-color);
  background-color: var(--second-bg);
  z-index: 999;
  font-size: 1rem;
}

.search-result:hover {
  background-color: var(--main-color);
  font-size: 1rem;
}

.circular-image {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: none;
  overflow: hidden;
}

.cancelIcon {
  background-color: white;
  color: var(--main-color);
  margin-left: -1.2em;
  margin-top: -0.5em;
  cursor: pointer;
}

.circular-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
