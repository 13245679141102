.circular-img {
  width: 5.5em;
  height: 5.5em;
  border-radius: 50%;
  border: none;
  overflow: hidden;
}

.circular-donut {
  /* width: 8em;
  height: 7em; */
  border: none;
  overflow: hidden;
}

.circular-img-container {
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: var(--box-shadow);
  /* width: 8em; */
  border-radius: 0.2em;

  border-radius: var(--border-radius);
  /* margin-top: 1em; */
  height: 100%;
  /* width: 40%;
  margin-right: 2em; */
}

.detailsSubTitle {
  font-size: 22px;
  font-weight: bold;
  color: #007fff;
}

.cardSubTitle {
  font-size: small;
  margin-left: 1em;
}

.subValue {
  margin-left: 2em;
  font-size: small;
}

.detailsTitle {
  font-size: "11px";
  color: #007fff;
}

.canvasjs-chart-canvas {
  border-radius: 12px;
}
