.circularImg {
  margin: 0.8em;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  background-color: blueviolet;
  border: none;
  overflow: hidden;
}

tr {
  border-bottom: 1px solid #ddd;
}

tr:hover {
  background-color: #d6eeee;
}

th {
  margin: "0em";
}

tr,
th {
  text-align: center;
}

td {
  padding-left: 2em;
  text-align: left;
  font-size: 14px;
}

.mainTitle {
  background-color: #6050dc;
  color: white;
}

.subTitle {
  text-align: left;
  background-color: #b0c4de;
}
