.theme-menu {
  position: fixed;
  /* right: -300px; */
  top: 3.8em;
  width: 300px;
  height: 100vh;
  padding: 20px;
  z-index: 99;
  border-radius: var(--border-radius);
  background-color: var(--second-bg);
  box-shadow: var(--box-shadow);
  transition: right 0.5s var(--transition-cubic);
}

.theme-menu.active {
  right: 0;
  background-color: var(--main-bg);
}

.theme-menu__close {
  position: absolute;
  top: 1em;
  right: 2em;
  background-color: transparent;
  font-size: 1.5rem;
}

.theme-menu__select {
  margin-top: 40px;
}

.mode-list {
  margin-top: 20px;
}

.mode-list > li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mode-list > li ~ li {
  margin-top: 10px;
}

.mode-list__color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 1.5rem;
}

.mode-list__color > i {
  transform: scale(0);
  transition: transform 0.5s var(--transition-cubic);
}

.mode-list__color.active > i {
  transform: scale(1);
}

.dropdown {
  position: relative;
  z-index: 99;
}

.dropdown__toggle {
  /* border: 0; */
  outline: 0;
  background-color: transparent;
  position: relative;
  background-color: var(--second-bg);
}

.dropdown__toggle > i {
  font-size: 2.5rem;
  color: var(--txt-color);
}

.dropdown__toggle-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: var(--main-color);
  color: var(--txt-white);
  font-size: 0.8rem;
}

.dropdown__content {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: max-content;
  max-width: 400px;
  background-color: vaR(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease 0s;
}

.dropdown__content.active {
  transform: scale(1);
  transition: transform 0.5s var(--transition-cubic);
}

.dropdown__footer {
  padding: 20px;
  text-align: center;
}
