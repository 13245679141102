.animatedGrid {
  font-size: medium;
}
.animatedGrid:hover {
  transition: all 0.5s ease-in-out;
}
.trailsText {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.imageStyles {
  border: 1px solid white;
  border-radius: 15px;
  transition: transform 0.3s ease;
}
.imageStyles:hover {
  transform: scale(1.1); /* Adjust the scale value as needed */
}

.trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: "1em";
}

.non-clickable-grid {
  pointer-events: none;
  opacity: 0.5;
}
